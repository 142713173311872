<template>
  <div
    v-shortkey="['esc']"
    class="modal-form"
    @click.self="$emit('update:showModalForm', false)"
    @shortkey="$emit('update:showModalForm', false)"
  >
    <div class="modal-form-container">
      <div class="modal-form__close">
        <i
          class="icon-delete"
          @click="$emit('update:showModalForm', false)"
        ></i>
      </div>
      <div
        id="amoForm"
        class="modal-form__amoForm"
      >
        <div
          id="amofroms_main_wrapper"
          class="amoforms__fields__editor-withborders  sidebar_none"
          style="max-width: 490px;"
        >
          <form
            id="amoforms_form"
            class="amoforms-form amoforms-view-form"
            action="https://forms.amocrm.com/queue/add"
            method="POST"
            enctype="multipart/form-data"
            style="height: 520px;"
            @submit.prevent="submitForm"
          >
            <div
              class="amoforms__fields__container amoforms__fields__container_text amoforms__fields__container_inside"
            >
              <div
                class="amoforms__fields__container__inner amoforms__fields__container__inner_text  amoforms__fields__container__inner_inside "
              >
                <div
                  class="amoforms__field__control amoforms__field__control_text js-amoforms-border-radius js-amoforms-field-height"
                  :class="{'amoforms__field__error': !firstName && error}"
                >
                  <input
                    v-model.trim="firstName"
                    type="text"
                    name="fields[name_1]"
                    placeholder="First Name"
                    class="amoforms_placeholder js-form-changes-skip text-input js-amoforms-font js-amoforms-color  amoforms-validate_required"
                  >
                  <div
                    v-if="!firstName && error"
                    class="amoforms__field__error_message"
                  >
                    Invalid required field
                  </div>
                </div>
                <div class="amoforms__field__required-inside">
                  *
                </div>
              </div>
            </div>

            <div
              class="amoforms__fields__container amoforms__fields__container_text amoforms__fields__container_inside"
            >
              <div
                class="amoforms__fields__container__inner amoforms__fields__container__inner_text  amoforms__fields__container__inner_inside "
              >
                <div
                  class="amoforms__field__control amoforms__field__control_text js-amoforms-border-radius js-amoforms-field-height"
                  :class="{'amoforms__field__error': !lastName && error}"
                >
                  <input
                    v-model.trim="lastName"
                    type="text"
                    name="fields[129675_1]"
                    placeholder="Last Name"
                    class="amoforms_placeholder js-form-changes-skip text-input js-amoforms-font js-amoforms-color  amoforms-validate_required"
                  >
                  <div
                    v-if="!lastName && error"
                    class="amoforms__field__error_message"
                  >
                    Invalid required field
                  </div>
                </div>
                <div class="amoforms__field__required-inside">
                  *
                </div>
              </div>
            </div>

            <div
              class="amoforms__fields__container amoforms__fields__container_multitext amoforms__fields__container_inside"
            >
              <div
                class="amoforms__fields__container__inner amoforms__fields__container__inner_multitext  amoforms__fields__container__inner_inside "
              >
                <div
                  class="amoforms__field__control amoforms__field__control_multitext js-amoforms-border-radius js-amoforms-field-height"
                  :class="{'amoforms__field__error': !email && error}"
                >
                  <input
                    v-model.trim="email"
                    type="email"
                    class="amoforms_placeholder js-form-changes-skip text-input js-amoforms-font js-amoforms-color  amoforms-validate_required amoforms-validate_email"
                    name="fields[115305_1][69175]"
                    placeholder="Email"
                  >
                  <div
                    v-if="!email && error"
                    class="amoforms__field__error_message"
                  >
                    Invalid required field
                  </div>
                </div>
                <div class="amoforms__field__required-inside">
                  *
                </div>
              </div>
            </div>

            <div
              class="amoforms__fields__container amoforms__fields__container_multitext amoforms__fields__container_inside"
            >
              <div
                class="amoforms__fields__container__inner amoforms__fields__container__inner_multitext  amoforms__fields__container__inner_inside "
              >
                <div
                  class="amoforms__field__control amoforms__field__control_multitext js-amoforms-border-radius js-amoforms-field-height"
                >
                  <input
                    v-model.trim="phone"
                    type="tel"
                    class="amoforms_placeholder js-form-changes-skip text-input js-amoforms-font js-amoforms-color  amoforms-validate_phone"
                    name="fields[115303_1][69163]"
                    placeholder="Phone"
                  >
                </div>
              </div>
            </div>

            <div
              class="amoforms__fields__container amoforms__fields__container_text amoforms__fields__container_inside"
            >
              <div
                class="amoforms__fields__container__inner amoforms__fields__container__inner_text  amoforms__fields__container__inner_inside "
              >
                <div
                  class="amoforms__field__control amoforms__field__control_text js-amoforms-border-radius js-amoforms-field-height"
                >
                  <input
                    v-model.trim="organization"
                    type="text"
                    name="fields[name_3]"
                    placeholder="Organization Name"
                    class="amoforms_placeholder js-form-changes-skip text-input js-amoforms-font js-amoforms-color "
                  >
                </div>
              </div>
            </div>

            <div class="amoforms__fields__submit">
              <div class="amoforms__submit-button__flex amoforms__submit-button__flex_center">
                <button
                  id="button_submit"
                  class="amoforms__submit-button amoforms__submit-button_rounded  text-input js-form-changes-skip js-amoforms-font js-amoforms-field-height"
                  :class="{'amoforms__submit_button_shake': disabledBtn && error}"
                  :disabled="disabledBtn"
                  type="submit"
                  style="color: #FFFFFF;
                  background-color: #ff4f4f;
                  border-radius: 53px;
                  font-weight: bold;"
                >
                  <span
                    class="amoforms__spinner-icon"
                    :class="{'show': disabledBtn && loader}"
                  ></span>
                  <span
                    class="amoforms__submit-button-text btn-chartform-download"
                    :class="{'hide': disabledBtn && loader}"
                  >Download</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import amoCrmApi from '@/api/amoCrmApi';

export default {
  name: 'ModalsFormSeven',
  props: ['showModalForm'],
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      organization: '',
      disabledBtn: false,
      error: false,
      loader: false,
    };
  },
  methods: {
    submitForm() {
      if (this.firstName && this.lastName && this.email) {
        this.disabledBtn = true;
        this.error = false;
        const data = {
          fields: [
            { name: 'First Name', value: this.firstName, position: 1 },
            { name: 'Last Name', value: this.lastName, position: 2 },
            { name: 'Email', value: this.email, position: 3 },
            { name: 'Phone', value: this.phone, position: 4 },
            { name: 'Organization Name', value: this.organization, position: 5 },
          ],
        };
        this.loader = true;
        amoCrmApi.sendLeadsToAmoSrm({ data, id: 7 }).then(({ data }) => {
          // console.log(data);
        }).catch((err) => {
          console.error('Error:', err);
        }).finally(() => {
          this.disabledBtn = false;
          this.error = false;
          this.loader = false;
          window.location.href = 'https://impactfactors.net/competitordownload';
        });
      } else {
        this.error = true;
        this.disabledBtn = true;
        setTimeout(() => {
          this.disabledBtn = false;
        }, 2000);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/sass/utils/variables";

.modal-form {
  position: fixed;
  top: 0;
  left: 0;
  /*width: 100vw;*/
  /*height: 100vh;*/
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($color-black, .9);

  &-container {
    position: relative;
    height: 520px;
    overflow: hidden;
  }

  &__close {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 15px;
    right: 15px;
    transition: color .2s ease-in;
    cursor: pointer;
    z-index: 1;

    &:hover {
      color: $color-white;
    }
  }

  &__amoForm {
    width: 500px;
    max-width: 100%;
    min-height: 100% !important;
  }
}

#amofroms_main_wrapper {
  background: #fff;
  padding: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .1);
  max-width: 509px;
  width: calc(100% - 10px);
  margin: 5px 5px 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

#amofroms_main_wrapper {
  color: #2e3640;
  box-sizing: border-box;
  border: 1px solid #D8D9DB;
  background: #00c1eb;
}

.amoforms-form {
  padding: 43px 36px 25px 36px;
  background-color: inherit;
  background-image: inherit;
  background-size: inherit;
  background-repeat: inherit;
  background-position: inherit;
  box-sizing: border-box;
}

#amofroms_main_wrapper.sidebar_none .amoforms-form {
  border-radius: inherit;
}

.amoforms__fields__container {
  width: 100%;
  position: relative;
  border: 1px solid transparent;
  box-sizing: border-box;
  margin-bottom: 18px;
}

.amoforms__fields__editor-withborders .amoforms__fields__container {
  box-sizing: border-box;
}

.amoforms__fields__container * {
  box-sizing: border-box;
}

.amoforms__fields__container__inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.amoforms__fields__container__inner_inside {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  -ms-grid-row-align: stretch;
  align-items: stretch;
  position: relative;
}

.amoforms__field__control_multitext,
.amoforms__field__control_text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #d8d9db;
  border-radius: 3px;
  padding: 0 17px;
  height: 53px;
  max-width: none;
}

.amoforms__field__control {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  box-sizing: border-box;
}

.js-amoforms-field-height {
  height: 53px !important;
  min-height: 53px !important;
}

.js-amoforms-border-radius {
  background-color: #F9F9F9;
  border-color: #D8D9DB;
  border-radius: 5px;
}

.amoforms__fields__container__inner_above .amoforms__field__control,
.amoforms__fields__container__inner_inside .amoforms__field__control,
.amoforms__fields__container__inner .amoforms__field__control_terms {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  max-width: none;
  font-size: 0;
  line-height: 0;
}

input {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  outline: none;
  font-family: inherit;
}

.amoforms__field__control_birthday .text-input,
.amoforms__field__control_date .text-input,
.amoforms__field__control_date_time .text-input,
.amoforms__field__control_multitext .text-input,
.amoforms__field__control_numeric .text-input,
.amoforms__field__control_smart_address_input .text-input,
.amoforms__field__control_legal_entity_input .text-input,
.amoforms__field__control_text .text-input,
.amoforms__field__control_url .text-input {
  background-color: transparent;
  border: none;
  text-overflow: ellipsis;
  border-radius: 0;
  border-bottom: 1px solid transparent;
  width: 100%;
  height: auto;
  padding: 0px 1px 0;
  font-size: 15px;
}

.amoforms__field__control_birthday .text-input,
.amoforms__field__control_date .text-input,
.amoforms__field__control_date_time .text-input,
.amoforms__field__control_multitext .text-input,
.amoforms__field__control_numeric .text-input,
.amoforms__field__control_smart_address_input .text-input,
.amoforms__field__control_legal_entity_input .text-input,
.amoforms__field__control_text .text-input,
.amoforms__field__control_widget .text-input,
.amoforms__field__control_url .text-input {
  font-size: 18px;
}

.amoforms__field__control .amoforms__field__error_message {
  display: block;
  position: absolute;
  text-align: right;
  bottom: 3px;
  right: 4px;
  width: 95%;
  height: 11px;
  color: red;
  font-size: 11px;
  line-height: 11px;
  pointer-events: none;
}

.amoforms__field__required-inside {
  color: red;
  position: absolute;
  right: 15px;
  top: 15px;
  line-height: 30px;
  display: block;
  font-size: 22px;
}

.amoforms__field__control.amoforms__field__error {
  border-color: #FF6666;
  border-right: 6px solid #FF6666;
}

/* btn */
.amoforms__fields__submit {
  padding: 6px 0;
  border: 1px solid transparent;
  margin-top: -10px;
}

.amoforms__fields__editor-withborders .amoforms__fields__submit {
  padding: 18px 0;
}

.amoforms__submit-button__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -5px -10px;
}

.amoforms__submit-button__flex_center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.amoforms__fields__submit .amoforms__submit-button {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  text-overflow: ellipsis;
  background-color: transparent;
  overflow: hidden;
  white-space: nowrap;
  max-width: 405px;
  justify-content: center;
  height: 52px;
  padding: 0 33px;
  border: 0;
  outline: none;
}

.amoforms__submit-button__flex .amoforms__submit-button {
  max-height: 52px;
  min-height: 52px;
  max-width: 100%;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding: 0 30px;
  margin: 0 5px 10px;
  box-sizing: content-box;
}

.amoforms__submit_button_shake {
  animation-fill-mode: both;
  animation-duration: 0.3s;
  animation-name: shake;
  background-color: #FF6666 !important;
  cursor: default !important;
}

/* loader */
.amoforms__spinner-icon {
  display: none;
  width: 16px;
  height: 16px;
  border: solid 2px transparent;
  border-radius: 100%;
  -webkit-animation: nprogress-spinner 900ms linear infinite;
  animation: nprogress-spinner 900ms linear infinite;
  border-top-color: #fff;
  border-left-color: #fff;
}
@keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.show {
  display: block;
}
.hide {
  display: none;
}
</style>
